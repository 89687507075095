@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';
@import '~@uppy/webcam/dist/style.css';
@import './Assets/Css/table.css';

@font-face {
  font-family: frutigerLight;
  src: url('./Assets/Fonts/frutiger45_light.ttf');
}
@font-face {
  font-family: frutigerRoman;
  src: url('./Assets/Fonts/frutiger55_roman.ttf');
}
@font-face {
  font-family: frutigerItalic;
  src: url('./Assets/Fonts/frutiger56_italic.ttf');
}
@font-face {
  font-family: frutigerBold;
  src: url('./Assets/Fonts/frutiger65_bold.ttf');
}
@keyframes modalFade {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #4066b3;
}
::-webkit-scrollbar-thumb {
  background: #dadada;
}
::-webkit-scrollbar-thumb:hover {
  background: #dadada;
}
.buttonTable {
  margin-left: 10px;
}
.ant-btn-primary {
  background: #009ee0;
}

.btnEdit {
  margin-left: 10px;
  color: white;
  border-radius: 7px;
  border-color: #444444;
  background-color: #444444;
}

.btnEditBlue {
  background: #009ee0;
  color: white;
  border-color: #009ee0;
}

.btnDelete {
  background: #d82d2d;
  margin-left: 10px;
  color: white;
  border-radius: 7px;
  border-color: #c43a3a;
}

.btnNext {
  background: #1db227;
  margin-left: 10px;
  color: white;
  border-radius: 10px;
}

.btnReset {
  border-radius: 10px;
  padding: 0 24px;
  border-color: #c43a3a;
  background: white;
  color: #c43a3a;
}

.capital {
  text-transform: capitalize;
}

.capitalFirst {
  text-transform: lowercase;
  display: block;
}

.capitalFirst::first-letter {
  text-transform: capitalize;
}
.textBold {
  font-weight: bold;
}
.textUnderline {
  text-decoration: underline;
}
.textUnderline a {
  color: black;
}
body {
  margin: 0;
  font-family: frutigerRoman, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container_breadcrumb {
  background-color: #fffdcc;
  width: 100%;
  padding: 8px 16px;
}
.container_breadcrumb_button {
  display: inline;
}
.button_breadcrumb {
  background-color: transparent;
  border: none;
  text-decoration: underline;
}
.breadcrumb_first {
  color: #fb6915;
  display: inline;
}
.breadcrumb_current {
  display: inline;
  margin-left: 8px;
  font-weight: bold;
}
.containerElearningTabPane {
  padding: 16px;
}
.loginBackgroundImage {
  height: 100vh;
  background-size: cover;
  background-position: right;
  position: relative;
  text-align: center;
}
.formLogin {
  padding-left: 5vw;
  padding-right: 5vw;
}
.logoCenterLogin {
  width: 30%;
  position: absolute;
  top: 37vh;
  display: block;
  margin-left: 32%;
}
.logoCenterFormLogin {
  width: 59%;
  margin-left: 20%;
  margin-top: 16vh;
  margin-bottom: 26px;
}
.zurichCMSLogo {
  background-color: white;
  width: 250px;
  height: 80px;
  padding-top: 7px;
  position: absolute;
  top: 5vh;
  left: 5vh;
}
.pZurichCMSLogo {
  font-size: 40px;
  color: #000066;
}
.zurichCopyrightLogin {
  position: absolute;
  bottom: 1vh;
  color: white;
  margin-left: 16vw;
  text-align: center;
  display: block;
  font-size: 16px;
}
.colRightFormLogin {
  background-color: #1f57ac;
  height: 100vh;
}
.formLogin input {
  height: 65px;
  font-size: 17px;
  /* padding-top: 23px; */
  font-weight: bolder;
  background: #4c79bd;
  border: none;
  color: #ffffff;
}
.formLogin span.formInputLogin {
  font-weight: bolder;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: #ffffff;
}
#inputPassword {
  height: 55px;
  background: none;
}
.formLogin span.ant-input-prefix {
  top: 30%;
  left: 30px;
  color: #ffffff;
}
.formInputLogin {
  margin-bottom: 20px;
}
.btnLogin {
  margin: auto;
  display: block;
  height: 40px;
  width: 150px;
  margin-top: 20px;
  background: #000066;
  border: none;
  border-radius: 10px;
  font-size: 17px;
}
.btnSubmit,
.btnSubmit:focus,
.btnSubmit:active {
  margin: auto;
  display: block;
  background: rgba(0, 0, 102, 1);
  border: none;
  border-radius: 10px;
  color: #ffffff;
}
.btnSubmitDisabled,
.btnSubmit:hover {
  margin: auto;
  display: block;
  background: rgba(0, 0, 102, 0.4);
  border-radius: 10px;
  color: #ffffff;
}
.btnLoginDisabled {
  margin: auto;
  display: block;
  height: 40px;
  width: 150px;
  margin-top: 20px;
  background: #000066 !important;
  border: none;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.2) !important;
}
.layoutTemplate {
  height: 100vh;
  overflow-x: hidden;
  background: rgba(0, 51, 153, 1);
}
.siderTemplate {
  background: rgba(0, 51, 153, 1);
}
.headerTemplate {
  background: rgba(0, 51, 153, 0.9);
  padding-left: 20px;
  border-bottom: rgba(255, 255, 255, 0.5) 2px solid;
  padding-right: 20px;
}
.contentTemplate {
  background: rgba(31, 87, 172, 1);
  padding: 15px;
  display: table;
  min-height: 90vh;
  width: 100%;
}
.footerTemplate {
  background: rgba(0, 51, 153, 0.9);
  color: white;
  height: 50px;
  padding-top: 15px;
  padding-left: 20px;
  position: relative;
}
.cmsVersionFooter {
  position: absolute;
  right: 10px;
}
.iconTriggerColapse {
  margin-top: 15px;
  font-size: 1em;
  text-align: center;
  cursor: pointer;
  color: white;
}
.logoText {
  width: 130px;
  margin-left: 28px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.btnLogoutHeader {
  background: #009ee0;
  border: none;
  color: white;
  position: absolute;
  right: 0;
  top: 15px;
}
.menuSider {
  background: rgba(0, 51, 153, 1) !important;
  max-height: 90vh;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 10px;
}
.ant-tabs-nav {
  margin-bottom: 0px !important;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  display: none;
}
.menuSider .ant-menu-submenu ul {
  background: rgba(0, 51, 153, 1) !important;
  box-shadow: none !important;
}
.ant-menu-item-selected {
  background: #00298f !important;
  border-left: 3px solid white;
}
.logoTextMin {
  width: 30px;
  margin-left: 25px;
  margin-top: 15px;
  margin-bottom: 12px;
}
.roleStatusHeader {
  position: absolute;
  top: -10px;
  color: white;
}
.usernameHeader {
  position: absolute;
  top: 10px;
  color: white;
  font-weight: bolder;
}
.forgotPasswordText {
  text-align: center;
  margin-top: 30px;
  color: #ffffff;
}
.contentModal {
  padding: 20px;
  padding-bottom: 0;
  background: #ffffff;
  max-height: 70vh;
  overflow-x: auto;
}
.contentModalBig {
  padding: 20px;
  padding-bottom: 0;
  background: #ffffff;
  max-height: 95vh;
  overflow-x: auto;
}
.footerModal {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-top: 10px !important;
  text-align: center;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.buttonCloseModal {
  position: absolute;
  right: 5px;
  font-weight: bolder;
  background: no-repeat;
  border: none;
  color: #ffffff;
  font-size: 20px;
  top: 8px;
}

.colorIconWarning {
  color: #ffe17d;
  font-size: 6vh;
}

.iconUserCustomSize {
  padding-top: 15px;
  font-size: 4vh;
  padding-right: 20px;
}

.buttonCloseModal:hover,
.buttonCloseModal:active,
.buttonCloseModal:focus {
  position: absolute;
  right: 5px;
  font-weight: bolder;
  background: no-repeat;
  border: none;
  color: #000000;
  font-size: 20px;
  top: 8px;
}
.headerModal {
  background: #000066;
  color: white;
  padding-left: 25px;
  height: 50px;
  line-height: 48px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
}
.headerModalNotif {
  background: white;
  color: white;
  padding-left: 25px;
  height: 50px;
  line-height: 48px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
}

.headerModal .headerTitle {
  font-weight: bold;
  color: #ffffff;
}
.modalStyleContainer {
  animation-name: modalFade;
  animation-duration: 0.3s;
  top: 35px;
}
.modalStyleContainer section {
  max-height: 95vh;
  overflow-y: hidden;
  background: none;
}
.commonCardStyle {
  margin-bottom: 30px;
  max-width: 90vw;
}
.navPagination {
  position: absolute;
  right: 10px;
  top: 0px;
}
.thTableHeader {
  height: 65px;
  vertical-align: inherit !important;
}
.btnDownloadTrackerReport {
  margin-top: 33px;
}
.labelCardTitle {
  background: white;
  margin-bottom: 30px;
  display: table;
  border-right: 10px solid #009ee0;
}
.labelCardTitle p {
  margin: 0px 20px 10px 20px;
  font-size: 20px;
  font-weight: bolder;
  color: #000066;
}
.labelCardTitle h3 {
  margin: 10px 20px 5px 20px;
  font-size: 16px;
  font-weight: 300;
  color: #444444;
}
.labelCardTitle p span {
  font-weight: 100;
  font-family: frutigerLight, sans-serif;
}
.btnActionDetail {
  margin-right: 10px;
}
.cardStyleZurich {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  border: none;
}
.titleCardStyleZurich {
  color: #ffffff;
  font-weight: bolder;
  font-size: 20px;
}
.cardDashboard {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  border: none;
}
.titleCardDashboard {
  color: #ffffff;
  font-weight: bolder;
}
.spanContestStatus {
  position: relative;
  width: 100px;
}
.contestStatusColor {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 8px;
}
.contestStatusColor1 {
  background-color: #0069e0;
}
.contestStatusColor2 {
  background-color: #f8b720;
}
.contestStatusColor3 {
  background-color: #55db55;
}
.contestStatusColor4 {
  background-color: #d82d2d;
}
.contestStatusName {
  position: absolute;
  top: 3px;
  left: 15px;
}
.imageContestReward {
  height: 350px;
  width: 500px;
  background-size: cover;
  margin: auto;
  border: 10px #dee2e6 solid;
  margin-bottom: 20px;
}
.dashboardValueContainer {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  color: #ffffff;
  margin: 20px 20px 40px 20px;
}
.dashboardValueContainer2 {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding-top: 20px;
  font-size: 14px;
  color: #ffffff;
}
.dashboardValueTitle {
  background-color: rgba(0, 51, 153, 0.4);
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 30px;
}
.dashboardValueDesc {
  padding: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dashboardValueTitleHasDesc {
  margin-bottom: 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dashboardValue {
  font-size: 40px;
  font-weight: bolder;
}
.dashboardValueUnit {
  margin-top: 10px;
}
.dashboardCardDosd {
  margin-left: 20px;
  margin-right: 20px;
}
.dashboardCardValue {
  margin-top: 20px;
}
.periodDashboard {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 0px;
}
.periodDashboard .iconPeriodDashboard {
  margin-right: 20px;
}
.containerWhiteZurich {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
}
.containerTabWhiteZurich {
  background-color: #ffffff;
  border-radius: 10px;
  border-top-left-radius: 0px;
  padding: 30px;
  max-width: 82vw;
}
.divInfoUpload .ant-row {
  margin-bottom: 5px;
  padding: 3px;
}
.widthInputVersion {
  width: 70px;
}
.btnFilterAplicationList {
  margin-top: 22px;
}
.btnSearchLicense {
  margin-top: 29px;
}
.btnRadius10 {
  border-radius: 10px;
  padding: 0 24px;
}
.agreementInputNumber {
  width: 10vh;
}
.cardCompleted {
  padding-top: 40px;
}
.tabLicenseModal .ant-tabs-tab {
  padding: 0;
  margin-right: 0px;
}
.tabLicenseModal .ant-tabs-bar {
  margin-bottom: 0px;
  border-bottom: 0px;
}
.tabPaneLicenseModal {
  padding: 15px;
  background: #003399;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px #dadada solid;
}
.tabLicenseModal .ant-tabs-ink-bar {
  background-color: #ffffff;
}
.tabLicenseModal .ant-tabs-tab-active .tabPaneLicenseModal {
  background: #ffffff;
  color: #003399;
}
.lineLicense,
.lineAgreement,
.linePendingRemarks {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-bottom: 40px;
}
.lineUploadLicense {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-bottom: 40px;
  padding-top: 25px;
}
.uploadScheuleStatusColor,
.licenseStatusColor,
.worldCheckStatusColor {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 8px;
}
.uploadScheuleStatusColorSuccess,
.licenseStatusColorSuccess,
.worldCheckStatusColorSuccess {
  background-color: #55db55;
}
.uploadScheuleStatusColorFailed,
.licenseStatusColorFailed,
.worldCheckStatusColorFailed {
  background-color: #d82d2d;
}
.worldCheckStatusName,
.uploadScheduleStatusName,
.licenseStatusName {
  position: absolute;
  top: 3px;
  left: 15px;
}
.licenseStatusColor {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 8px;
}
.licenseStatusColorSuccess {
  background-color: #55db55;
}
.licenseStatusColorFailed {
  background-color: #d82d2d;
}
.btnFilenet {
  margin-top: 30px;
}
.tagColorProgress1 {
  border: 1px #009ee0 solid;
}
.tagColorProgress2 {
  border: 1px #c5cccc solid;
}
.fontColorAgentMonitoringTag {
  color: #000000;
}
.containerWhiteAgentMonitoring {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
}
.containerWhiteAgentMonitoringTable {
  margin-bottom: 10px;
  max-height: 400px;
  max-width: 800px;
  overflow-y: scroll;
}
.cardAgentMonitoring,
.cardLicense {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
}
.contentLicense {
  padding: 20px;
  padding-bottom: 0;
  background: #f5f7f7;
  max-height: 75vh;
  overflow-x: auto;
}

.tagStatPopupAgentMonitoring {
  float: right;
}
.titlePopupAgentMonitoringStatus,
.titlePopupLicenseStatus {
  float: right;
  font-size: 13px;
}
.licenseWarningIconCenter,
.licenseTextCenter,
.tagStatLicenseCenter {
  text-align: center;
}
.tagStatLicenseProgress {
  color: #ffffff;
  width: 135px;
}
.tagStatusModal {
  width: 110;
  text-align: 'center';
}
.selectLicense {
  width: 270px !important;
}
.selectNotesPaymentSlip {
  padding-top: 20px;
}
.titleContentLicenseModal {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.contentLicenseModal {
  margin-top: 10px;
}
.contentNewAgentMonitoringDetailCandidate,
.contentLicenseDetailCandidate {
  margin-bottom: 20px;
}
.licenseSpacingTabRow {
  margin-top: 20px;
}

.titleNameAgentMonitoring {
  float: left;
}

.titleAgentMonitoring {
  color: #003399;
  font-weight: bold;
  font-size: 20px;
}
.titleCandidateAgentMonitoring,
.titleLicenseModal {
  color: #003399;
  font-weight: bold;
  font-size: 18px;
}
.titleContractNumberAgent {
  font-size: 13px;
}
.spacingTitleLicenseModal {
  margin-top: 25px;
}
.detailCandidateLicense {
  margin-bottom: 5px;
}
.tagLabelStatusLicense {
  color: #ffffff;
  width: 120px;
  text-align: center;
}
.spacingBtnSecondRow {
  float: right;
  padding-bottom: 20px;
}
.spacingBtnSecondRow2 {
  margin-right: 15px;
}
.btnDownloadPrivy {
  border-radius: 15px;
}
.btnSubmitLicenseQaClean {
  margin-top: 10px;
  width: 170px;
  border-radius: 10px;
}
.btnCloseNotificationModal {
  margin-top: 40px;
  width: 170px;
  border-radius: 10px;
}
.btnClodeValidationModal {
  background-color: #3e3e3e;
  border-radius: 10px;
  color: white;
  width: 170px;
  margin-top: 40px;
}
.tabContestUpload .ant-tabs-tab {
  padding: 0;
  margin-right: 0px;
}
.tabContestUpload .ant-tabs-bar {
  margin-bottom: 0px;
  border-bottom: 0px;
}
.tabPaneContestUpload {
  padding: 15px;
  background: #003399;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.tabContestUpload .ant-tabs-ink-bar {
  background-color: #ffffff;
}
.tabContestUpload .ant-tabs-tab-active .tabPaneContestUpload {
  background: #ffffff;
  color: #003399;
}
.titleHistoryDetailUpload {
  font-weight: bolder;
}
.alertErrorModuleUploadHistory {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 20px;
  color: #444444;
}
.alertErrorModuleUploadHistory span.anticon {
  margin-left: 20px;
}
.alertErrorModuleUploadHistory span.ant-alert-message {
  margin-left: 40px;
}
.rowTablePermission {
  padding: 2px;
  padding-left: 20px;
  padding-right: 20px;
  background: #fafbfb;
}
.rowTablePermissionOdd {
  background: #f5f7f7;
}
.rowTablePermissionHeader {
  background: #ecf0ef;
  font-weight: bolder;
}
.rowCheckboxPermission {
  margin: auto;
  text-align: center;
}
.labelFormItemZurich {
  color: #003399;
  font-weight: bolder;
  text-transform: uppercase;
}
.forceHelpLabel {
  margin-bottom: 30px;
  font-style: italic;
}
.classNameFormSearchUsername {
  margin-bottom: 0px;
}
.ant-tree-title {
  word-break: break-all;
}
.container404 {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: right bottom;
}
.ant-alert {
  margin-bottom: 30px;
}
.notFoundImage {
  height: 15vw;
  margin-top: 15vh;
  margin-left: 5vw;
}
.h1NotFound {
  font-size: 25px;
  margin-left: 7vw;
  margin-top: 5vh;
  color: #444444;
  margin-bottom: 5px;
  text-align: center;
}
.buttonNotFound {
  margin-top: 20px;
  background: #000066;
  border: 1px #000066 solid;
  color: white;
  margin-left: 7vw;
}
.ant-card.ant-card-bordered.commonCardStyle.RegistrationForm {
  width: 90%;
  margin-left: 5vw;
  margin-top: 30px;
  border-radius: 15px !important;
}
.ant-card.ant-card-bordered.commonCardStyle.RegistrationForm .ant-card-head {
  background-color: #4066b3;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.titleCardRegistration {
  color: #ffffff;
  font-size: 20px;
  font-family: frutigerBold, sans-serif;
}
.containerStepsRegistration {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  margin-left: 32px;
  width: 50px;
  height: 50px;
}
.ant-steps-item-icon .ant-steps-icon {
  font-size: 22px;
  position: absolute;
  top: 10px;
  left: 18px;
}
.ant-steps-item-tail {
  top: 25px;
}
.anticon.anticon-check.ant-steps-finish-icon {
  margin-left: -3px;
}
.avatarActived {
  background-color: #1890ff;
}
.avatarRegistration {
  border: 1px solid #1890ff;
  color: #1890ff;
  background-color: #ffffff;
}
.avatarDisabled {
  background-color: #ecf0ef;
  color: #bfbfbf;
}
.containerCardBodyRegistration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.containerComponentRegistration {
  width: 100%;
}
.titleSectionRegis {
  font-size: 18px;
  font-family: frutigerBold, sans-serif;
  color: #fb6915;
  margin-bottom: 20px;
}
.titleSectionBank {
  font-size: 18px;
  font-family: frutigerBold, sans-serif;
  color: #fb6915;
  margin-bottom: 5px;
}
.descSectionBank {
  font-size: 13px;
  margin-bottom: 25px;
}
.lineSectionRegis {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-bottom: 30px;
}
.ant-input .inputRegistration {
  border-radius: 10px;
  border: 1px solid #dadada;
  height: 30px;
}
.capitalTitle {
  font-size: 18px;
  font-weight: 500;
}
.ant-row.ant-form-item.registrationForm {
  margin-bottom: 0px;
}
.btnBackRegsitration {
  background-color: #ffffff;
  color: #009ee0;
  border: 2px solid #009ee0;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  width: 130px;
  height: 40px;
  margin-right: 15px;
}
.btnNextRegistration {
  background-color: #009ee0;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  width: 130px;
  height: 40px;
}
.btnEditRegsitration {
  background-color: #444444;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  width: 130px;
  height: 40px;
  margin-right: 15px;
}
.btnSaveRegsitration {
  background-color: #1db227;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  width: 130px;
  height: 40px;
}
.containerButtonBack {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btnAddRegisForm {
  border-radius: 10px;
  background-color: #009ee0;
  margin-right: 20px;
}
.btnResetRegisForm {
  border-radius: 10px;
  background-color: #ffffff;
  border: 2px #d82d2d solid;
  color: #d82d2d;
  padding-left: 25px;
  padding-right: 25px;
}
.labelMultipleInput {
  text-transform: capitalize;
  font-weight: bold;
  float: left;
  margin-right: 40px;
}
.containerDynamicTabs {
  background-color: #ffffff;
}
.ant-tabs.ant-tabs-top.ant-tabs-card.dynamicTabs .ant-tabs-content-holder {
  background-color: white;
}
.ant-tabs.ant-tabs-top.ant-tabs-card.dynamicTabs div.ant-tabs-content-holder {
  padding: 0;
}
.ant-tabs.ant-tabs-top.ant-tabs-card.dynamicTabs
  div.ant-tabs-nav
  div.ant-tabs-nav-wrap
  div.ant-tabs-nav-list
  div.ant-tabs-tab.ant-tabs-tab-active {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ffffff;
  border: 1px #dadada solid;
  padding-left: 35px;
  padding-right: 35px;
}
.ant-tabs.ant-tabs-top.ant-tabs-card.dynamicTabs
  div.ant-tabs-nav
  div.ant-tabs-nav-wrap
  div.ant-tabs-nav-list
  div.ant-tabs-tab.ant-tabs-tab-active
  div.ant-tabs-tab-btn {
  color: #003399;
}

.ant-tabs.ant-tabs-top.ant-tabs-card.dynamicTabs
  div.ant-tabs-nav
  div.ant-tabs-nav-wrap
  div.ant-tabs-nav-list
  div.ant-tabs-tab.ant-tabs-tab-disabled {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ecf0ef;
  border: 1px #ecf0ef solid;
  border-bottom: none;
  padding-left: 35px;
  padding-right: 35px;
}
.ant-tabs.ant-tabs-top.ant-tabs-card.dynamicTabs
  div.ant-tabs-nav
  div.ant-tabs-nav-wrap
  div.ant-tabs-nav-list
  div.ant-tabs-tab.ant-tabs-tab-disabled
  div.ant-tabs-tab-btn {
  color: #bfbfbf;
}
.ant-tabs.ant-tabs-top.ant-tabs-card.dynamicTabs
  div.ant-tabs-nav
  div.ant-tabs-nav-wrap
  div.ant-tabs-nav-list
  div.ant-tabs-tab {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #003399;
  border: 1px #003399 solid;
  border-bottom: none;
  padding-left: 35px;
  padding-right: 35px;
}
.ant-tabs.ant-tabs-top.ant-tabs-card.dynamicTabs
  div.ant-tabs-nav
  div.ant-tabs-nav-wrap
  div.ant-tabs-nav-list
  div.ant-tabs-tab
  div.ant-tabs-tab-btn {
  color: #ffffff;
}
.lineDynamicTabs {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-top: 20px;
  margin-bottom: 10px;
}
.containerDynamicTabPane {
  padding: 32px;
}
.questionText {
  font-family: frutigerLight, sans-serif;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.answerText {
  font-family: frutigerLight, sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ant-collapse-item.ant-collapse-item-active.panelDynamicTabs
  div.ant-collapse-header {
  background-color: #f5f7f7;
  font-family: frutigerBold, sans-serif;
  font-size: 16px;
}
.ant-collapse-item.panelDynamicTabs div.ant-collapse-header {
  background-color: #f5f7f7;
  font-family: frutigerBold, sans-serif;
  font-size: 16px;
}
.contentDetailQa {
  padding: 20px;
  padding-bottom: 0;
  background: #f5f7f7;
  max-height: 75vh;
  overflow-x: auto;
}
.containerCardDetailRegistration {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
}
.titleDetailCandidate {
  font-family: frutigerBold, sans-serif;
  font-size: 32px;
  color: #003399;
}
.btnCancelQa {
  background-color: #d82d2d;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
}
.btnEditQa {
  background-color: #444444;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 20px;
}
.containerStatusQa {
  float: right;
}
.lineDetailQa {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-top: 10px;
  margin-bottom: 30px;
}
.lineDetailDocumentQa {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-top: 50px;
  margin-bottom: 20px;
}
.ant-select.selectStatusWorld.ant-select-single.ant-select-show-arrow {
  width: 30% !important;
}
.inputCustomWorldCheck {
  width: 60%;
}
.ant-checkbox-wrapper.checkAgentActive {
  margin-bottom: 10px;
}
.btnUploadQa {
  border-radius: 10px;
}
.containerContentCancelCandidat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btnUndoCandidate {
  background-color: #3e3e3e;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  padding-left: 25px;
  padding-right: 25px;
}
.btnRejectCandidate {
  background-color: #d82d2d;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 20px;
}
.btnDetailQa {
  color: #003399;
}
.imageDynamicTabs {
  width: 400px;
  max-height: 400px;
}
.imageDynamicTabsDocuments {
  max-width: 300px;
  max-height: 400px;
  margin-bottom: 30px;
}
.labelDocuments {
  font-weight: bold;
  margin-bottom: 15px;
}
.ant-spin-nested-loading {
  background-color: rgba(0, 0, 0, 0.75);
}
.ant-spin-text {
  color: #003399;
  font-weight: bold;
  font-size: 20px;
  margin-top: 110px;
}
.btnEditTableRegis {
  border: none;
  color: #003399;
}
.btnDeleteTableRegis {
  border: none;
  color: #d82d2d;
  margin-left: 5px;
}
.btnArrowRight {
  color: #ffffff;
  background-color: #1db227;
  margin-left: 10px;
}
.btnDuplicateSubModule {
  color: #ffffff;
  background-color: #444444;
  margin-left: 10px;
  border: #444444;
}
.btnSaveEditBeneficary {
  background-color: #1db227;
  border-radius: 10px;
  color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  border: none;
  margin-right: 15px;
}
.imgEdit {
  width: 100px;
  height: 200px;
}
.btnDeleteImage {
  float: right;
  background-color: #d82d2d;
  border: none;
  width: 20%;
}
.containerDetail {
  float: left;
  width: 80%;
}
.containerTitle {
  width: 70%;
  margin-top: 20px;
  overflow: auto;
}
.imgInfoNew {
  background-color: #009ee0;
  color: #ffffff;
  border-radius: 10px;
  width: 50px;
  padding: 5px 10px;
}
.ant-row.ant-form-item.uploadAajiQa div.ant-col.ant-form-item-label {
  width: 100%;
  text-align: left;
}
.btnSubmitPendingRamrks {
  border-radius: 10px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #009ee0;
  color: white;
}
.btnSubmiEditPendingRamrks {
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #1db227;
  border: none;
  color: white;
}
.btnEditPendingRemarks {
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #444444;
  border: none;
  color: white;
}
.txtTitleStatusClean {
  font-family: frutigerLight, sans-serif;
  font-size: 14px;
  color: #3e3e3e;
  margin-bottom: 5px;
}
.txtStatusDefault {
  font-family: frutigerBold, sans-serif;
  font-size: 24px;
  color: #bfbfbf;
}
.txtStatusNotClean {
  font-family: frutigerBold, sans-serif;
  font-size: 24px;
  color: #d82d2d;
}
.txtStatusClean {
  font-family: frutigerBold, sans-serif;
  font-size: 24px;
  color: #1db227;
}
.btnNotClean,
.btnNotClean:hover,
.btnNotClean:focus {
  border-radius: 5px;
  border: none;
  background-color: #d82d2d;
  font-family: frutigerLight, sans-serif;
  margin-right: 20px;
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
}
.btnClean,
.btnClean:hover,
.btnClean:focus {
  border-radius: 5px;
  border: none;
  background-color: #1db227;
  font-family: frutigerLight, sans-serif;
  margin-right: 10px;
  margin-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
}
.lineDetailQaStatus {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-top: 20px;
  margin-bottom: 20px;
}
.txtTitleRemarks {
  font-family: frutigerBold, sans-serif;
  font-size: 18px;
  color: #3e3e3e;
}
div#inputPendingRemakrs.ant-radio-group.ant-radio-group-outline {
  width: 80%;
}
.btnAddPendingRemarks {
  background: rgba(0, 0, 102, 1);
  border: none;
  border-radius: 10px;
  color: #ffffff;
}
.btnDeleteItemPending,
.btnDeleteItemPending:hover,
.btnDeleteItemPending:focus {
  background-color: #fafbfb;
  border: none;
  color: #d82d2d;
  box-shadow: none;
  float: left;
}
.containerItemPending {
  background-color: #fafbfb;
  width: 100%;
  padding: 10px;
  margin-bottom: 7px;
}
.txtTitleItemPending {
  margin-left: 45px;
  margin-top: 3px;
}
.btnSubmitQa {
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
}
.buttonViewFileNet {
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
}
.txtItemPending {
  margin-top: 10px;
  background-color: #ecf0ef;
  border-radius: 5px;
  padding: 5px;
  color: #948e8b;
}
.containerTxtItemPending {
  width: 100%;
  overflow: auto;
}
.titleItemPending {
  float: left;
  width: 15%;
  text-decoration: underline;
  text-align: left;
  font-size: 16px;
  padding-top: 15px;
}
.txtItemPendingRightContent {
  margin-top: 10px;
  background-color: #ecf0ef;
  border-radius: 5px;
  padding: 5px;
  color: #948e8b;
  float: right;
  width: 85%;
}
.lineDetailWorldCheck {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-top: 20px;
  margin-bottom: 20px;
}
.containerStatusWorldCheckSuccess {
  width: 100%;
  height: 60px;
  background-color: #f1fbff;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 225px;
  display: flex;
  flex-direction: row;
}
.containerIconStatusWorldCheckSuccess {
  background-color: #35c97a;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.containerStatusWorldCheckFailed {
  width: 100%;
  height: 60px;
  background-color: #ffefef;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 225px;
  display: flex;
  flex-direction: row;
}
.containerIconStatusWorldCheckFailed {
  background-color: #d82d2d;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.txtDetailStatus {
  font-weight: bold;
  font-size: 16px;
  margin-top: 3px;
  margin-left: 15px;
}
.containerHeaderExamSchedule {
  width: 100%;
}
.dateNowExamSchedule {
  font-size: 16px;
  float: left;
}
.ant-radio-group.ant-radio-group-outline.radioExamSchedule {
  float: right;
  border-radius: 5px;
}
.ant-row.ant-form-item.examSchedule {
  margin-bottom: 0px;
}
.ant-radio-group.ant-radio-group-outline.radioExamSchedule
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #003399;
  border: 2px #003399 solid;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
}
.ant-radio-group.ant-radio-group-outline.radioExamSchedule
  .ant-radio-button-wrapper {
  background-color: white;
  border: 2px #003399 solid;
  color: #003399;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 1px;
}
.selectedDateModal {
  font-size: 16px;
  color: #3e3e3e;
  font-weight: bold;
}
.containerListExamSchedule {
  margin-top: 20px;
  font-size: 16px;
}
.containerExamSchedule {
  margin-bottom: 25px;
}
.containerDetailExamSchedule {
  font-size: 17px;
  margin-top: 20px;
}
.lineDetailSchedule {
  color: #f5f7f7;
  margin-top: 25px;
  border-top: none;
}
.containerParticipants {
  padding-top: 10px;
  margin-top: 10px;
  min-height: 50px;
  background-color: #fafbfb;
}
.txtExamStatus {
  margin-top: 20px;
}
.containerPendingItemLicense {
  border-radius: 5px;
  border: 1px #dadada solid;
  min-height: 200px;
  max-height: 200px;
  margin-bottom: 30px;
  overflow-y: scroll;
}
.txtLocationExam {
  font-weight: bold;
  font-size: 24px;
}
.lineExamLicense {
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-top: 8px;
  margin-bottom: 8px;
}
.selectStatementType {
  margin-top: 10px;
}
.ant-select.selectStatementType.ant-select-single.ant-select-show-arrow {
  width: 40% !important;
}
.linePrivy {
  border: 2px solid #dadada;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.TitleUploadPrivy {
  font-weight: bold;
  margin-top: 20px;
}
.imgFileNet {
  height: 250px;
  font-size: 40px;
  color: #bfbfbf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btnFileNet {
  font-size: 18px;
  text-decoration: underline;
}
.backgroundElearning {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background-size: cover;
  padding: 16px;
  color: white;
  position: relative;
}
.backgroundElearning span {
  position: absolute;
  bottom: 16px;
  font-size: 24px;
}
.containerDescDashboardElearning {
  margin: 24px 0;
  width: 100%;
  padding: 0;
  color: white;
}
.containerDescDashboardElearning div {
  font-size: 40px;
  font-weight: bold;
}
.containerDescDashboardElearning span {
  font-size: 16px;
}
.containerContentDashboardTrainer {
  color: white;
}
.detailDashboardTrainer {
  margin-bottom: 24px;
}
.titleDetailDashboardTrainer {
  font-weight: 500;
  padding-right: 16px;
}
.descDetailDashboardTrainer {
  font-weight: bold;
  padding-left: 8px;
}
.containerContentTabPaneDashboardTrainer {
  width: 100%;
  background-color: white;
  padding: 24px;
}
.btnRefreshDashboardTrainer {
  background-color: white;
  border: 1px solid #003399;
  border-radius: 10px;
  color: #003399;
  padding: 0 24px;
}
.btnDownloadTrainer {
  border-radius: 5px;
  margin-top: 30px;
  margin-left: 16px;
  padding: 0 24px;
}
.containerStepSubModul {
  padding: 0 40%;
}
.containerContentStepSubModul {
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 40px;
  margin-top: 30px;
}
.TitleStepsSubModul {
  background-color: #444444;
  color: white;
  padding: 8px;
  margin-bottom: 16px;
}
.containerGridTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
.inputExam {
  width: 80% !important;
  border: 1px solid #dadada;
  border-radius: 5px;
}
.inputSubModul {
  border: 1px solid #dadada;
  border-radius: 5px;
}
.containerInputTwo {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 20px;
}
.inputNumberSubModul {
  width: 80px;
  margin-right: 16px;
}
.containerBackgroundInputSubModul {
  background-color: #f5f7f7;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 16px;
}
.containerBackgroundInputModule {
  background-color: #f5f7f7;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 16px;
}
.containerUserLevels {
  background-color: #fafbfb;
  height: 250px;
  max-height: 250px;
  border-radius: 10px 10px 0 0;
  padding: 16px;
  overflow: auto;
}
.containerTotalUserLevels {
  background-color: #ecf0ef;
  padding: 8px;
  border-radius: 0 0 10px 10px;
}
.lineSubModulLevelMapping {
  border: 1px solid #ecf0ef;
  border-right: none;
  border-left: none;
  border-bottom: none;
  margin: 16px 0;
}
.titleAssignedUser {
  font-weight: bold;
  color: #003399;
  margin-bottom: 20px;
}
.containerFormLicense {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.ant-checkbox-group.multipleCheckboxLicenseStatus
  .ant-checkbox-wrapper.ant-checkbox-group-item {
  display: block;
  margin-bottom: 4px;
}
.ant-radio-group.multipleRadioButton {
  width: 80%;
}
.containerOtherCondition {
  background-color: #f5f7f7;
  border-radius: 10px;
  padding: 16px;
  margin-top: 8px;
}
.titleMultipleChecboxOtherCondition {
  display: inline-block;
  width: 100px;
}
.selectSubModul {
  width: 120px;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 16px;
  margin-right: 16px;
}
.containerInputAdditionalCondition {
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-bottom: 40px;
}
.selectSubModuleAddtionalCondition {
  width: 100%;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 3px;
}
.inputNumberAddtionalCondition {
  border: 1px solid #dadada;
  border-radius: 2px;
  width: 80px;
  height: 32px;
  margin-right: 16px;
  padding: 0 8px;
}
.inputNumberAddtionalCondition:hover {
  border: 1px solid #1890ff !important;
}
.inputNumberAddtionalCondition:focus {
  border: 1px solid #1890ff !important;
}
.inputSelectAdditionalCondition {
  margin-left: 62px;
  width: 400px !important;
}
.formSelectAdditionalCondition
  .ant-col.ant-form-item-control
  .ant-form-item-explain {
  margin-left: 62px;
}
.inputSelectAddtionalConditionContent {
  width: 400px !important;
  margin-left: 36px;
}
.formSelectAddtionalConditionContent
  .ant-col.ant-form-item-control
  .ant-form-item-explain {
  margin-left: 36px;
}
.inputSelectAddtionalConditionContentName {
  width: 400px !important;
  margin-left: 32px;
}
.formSelectAddtionalConditionContentName
  .ant-col.ant-form-item-control
  .ant-form-item-explain {
  margin-left: 32px;
}
.btnAddSubModule {
  background-color: #003399;
  border-radius: 5px;
  border: none;
  margin-right: 16px;
}
.btnResetExam {
  background-color: white;
  border: 1px solid #d82d2d;
  color: #d82d2d;
  width: 80%;
  border-radius: 10px;
}
.btnResetSubModule {
  background-color: white;
  border: 1px solid #d82d2d;
  color: #d82d2d;
  border-radius: 5px;
}
.btnAddSubModule {
  background-color: white;
  border: 1px solid #47b0e6;
  color: #47b0e6;
  border-radius: 5px;
}
.ContainerButtonSubModule {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.labelRequired {
  color: red;
}
.containerItemListSubModule {
  overflow: auto;
  background-color: #ecf0ef;
  padding: 8px 16px;
  border-radius: 5px;
  margin-bottom: 8px;
}
.btnDeleteItemList {
  background-color: #d82d2d;
  border: none;
  color: white;
  font-size: 16px;
  float: left;
  border-radius: 5px;
  width: 3%;
}
.btnDeleteItemTable {
  background-color: #d82d2d;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 5px;
}
.itemNameSubModule {
  float: right;
  width: 95%;
  font-size: 16px;
}
.btnSaveSubmodule {
  background-color: #1db227;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  width: 130px;
  height: 40px;
  margin-left: 8px;
}
.ant-input-number.inputNumberSubModuleInline {
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-right: 16px;
}
.ant-input-number.inputNumberSubModuleInlineWithoutButton {
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-right: 16px;
}
.ant-input-number.inputNumberSubModuleInlineWithoutButton
  .ant-input-number-handler-wrap {
  display: none;
}
.inputAnswerInline {
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-right: 16px;
}
.InputSelectOtherCondition {
  width: 100px !important;
  margin-right: 16px;
}
.ant-tag.ant-tag-warning.tagsContentManagement {
  width: 100%;
  padding: 8px 16px;
  border: none;
  background-color: #fffdcc;
  font-size: 16px;
  margin-bottom: 16px;
}
.textTagsContentManagement {
  display: inline;
  color: black;
  font-size: 14px;
}
.titleQuestion {
  width: 400px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}
.textSlideName {
  width: 200px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}
.textFileTypeSlide {
  text-transform: capitalize;
}
.disclaimerQuestionContent {
  background-color: #fffdcc;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 20px;
}
.container_transfer_text {
  display: grid;
  grid-template-columns: 8fr 1fr 8fr;
  margin-bottom: 20px;
}
.box_transfer_text {
  border: 1px solid #dadada;
}
.title_box_transfet_text {
  background-color: #444444;
  color: #ffffff;
  padding: 8px;
}
.text_title_box_transfer {
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
}
.container_checkbox_transfer_text {
  padding: 16px;
}
.container_checkbox_transfer_text
  .ant-row.ant-form-item
  .ant-col.ant-form-item-control
  .ant-form-item-explain {
  display: none;
}
.line_transfer_text {
  border: 1px solid #ecf0ef;
  opacity: 1;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.content_box_transfer_text {
  padding: 16px;
}
.content_box_transfer_text
  .ant-row.ant-form-item
  .ant-col.ant-form-item-control
  .ant-form-item-explain {
  display: none;
}
.ant-checkbox-group.multipleCheckboxTransferText
  .ant-checkbox-wrapper.ant-checkbox-group-item {
  display: block;
  margin-bottom: 8px;
}
.seleceted_transfer_text {
  margin-bottom: 8px;
}
.container_button_transfer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btnTransferText {
  border-radius: 5px;
  border: 1px solid #009ee0;
  background-color: #ffffff;
  color: #009ee0;
}
.btnResetContentQuestion {
  border: 1px solid #c43a3a;
  color: #c43a3a;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px 30px;
  margin-right: 10px;
}
.btnCreateContentQuestion {
  border-radius: 10px;
  padding: 0px 30px;
}
.title_multiple_choice {
  font-size: 16px;
  margin: 16px 0;
}
.container_list_question {
  background-color: #fafbfb;
  padding: 8px;
  height: 200px;
  max-height: 200px;
  box-sizing: border-box;
  overflow: auto;
}
.question_multiple_choice {
  background-color: #ecf0ef;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 4px;
}
.container_total_list_multiple_choice {
  background-color: #ecf0ef;
  padding: 8px;
  border-radius: 0 0 10px 10px;
}
.line_detail_feedback {
  border: 1px solid #ecf0ef;
  border-left: none;
  border-right: none;
  border-top: none;
  margin-top: 32px;
  margin-bottom: 16px;
}
.container_list_answer {
  margin-bottom: 16px;
  width: 70%;
  display: grid;
  grid-template-columns: 2fr 8fr 1fr 2fr;
}
.label_list_answer {
  padding: 8px 0;
}
.value_list_answer {
  background-color: #f1fbff;
  border: 1px solid #dadada;
  padding: 8px;
  margin-right: 16px;
  border-radius: 5px;
  margin-left: 16px;
}
.value_list_point {
  background-color: #f1fbff;
  border: 1px solid #dadada;
  padding: 8px;
  margin-right: 16px;
  border-radius: 5px;
}
.formFeedback {
  background-color: #ffffff;
  height: auto;
  position: absolute;
  width: 99%;
  top: 50px;
  left: 0;
}
.container_input_feedback {
  margin-top: 16px;
  margin-left: 16px;
  width: 80%;
  display: grid;
  grid-template-columns: 5fr 2fr 1fr 1fr 1fr;
}
.inputTextCategory {
  width: 90% !important;
}
.labelSwitchStatus {
  margin-top: 35px;
  margin-left: 50px;
}
.inputStatus {
  margin-top: 35px;
  margin-left: 24px;
  width: 60px;
}
.labelInfoStatus {
  margin-top: 35px;
}
.labelInfoStatusInContent {
  margin-top: 35px;
  margin-left: 10px;
}
.statusCategoryBank {
  margin-bottom: 9px;
}
.lineFeedback {
  border: 1px solid #ecf0ef;
  border-left: none;
  border-right: none;
  border-top: none;
  margin-bottom: 30px;
}
.container_empety {
  width: 100%;
  height: 120px;
}
.btnEditFeedbackNonSelf {
  background-color: #444444;
  color: white;
  padding: 4px 24px;
  border-radius: 10px;
}
.btnSaveChangeFeedbackNonSelf {
  background-color: #1db227;
  padding: 4px 24px;
  border: none;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .zurichCopyrightLogin {
    position: absolute;
    bottom: 1vh;
    color: white;
    margin-left: 5vw;
    text-align: center;
    display: block;
    font-size: 16px;
  }
}
@-moz-document url-prefix() {
  .contentTemplate {
    height: 80vh;
  }
}
.uploadFileCityLabel {
  font-size: 18px;
  color: #003399;
}
.btnUploadFileCity {
  width: 98%;
}
.breadcrumbLibrary {
  background: #ecf0ef;
  padding: 10px;
  margin-bottom: 20px;
}
.searchLibrary {
  margin-bottom: 20px;
}
.divTitleCollection {
  position: relative;
}
.divTitleCollection p {
  font-weight: bolder;
}
.iconTitleCollection {
  position: absolute;
  right: 0;
  top: 0px;
  font-size: 24px;
  color: #009ee0;
  cursor: pointer;
}
.iconDirTree {
  color: #f8b721;
  margin-right: 5px;
}
.iconFileTree {
  color: #fb5555;
  margin-right: 5px;
}
.iconFileTreeDetail {
  color: #fb5555;
  margin-right: 5px;
  font-size: 30px;
  margin-top: 6px;
}
.cardTitleUploadLibrary {
  color: #003399;
  font-size: 18px;
  font-weight: bolder;
}
.titleUploadFileLicenseModal,
.titleUploadFileCityModal,
.labelAgreementModal,
.labelInvitationBig {
  color: #003399;
  font-weight: bolder;
  font-size: 18px;
}
.directoryTreeLib {
  overflow-y: auto;
}
.breadcrumbList {
  cursor: pointer;
}
.fileNameDetailLibrary {
  color: #3e3e3e;
  margin-bottom: 0px;
}
.fileSizeDetailLibrary {
  color: #948e8b;
}
.templateSms .ant-form-item {
  margin-bottom: 0px;
}
.templateSms .ant-form-item .ant-form-item-control .ant-form-item-explain {
  display: none;
}
.selectReportType {
  width: 270px !important;
  margin-top: 8px;
}
.rowDownloadReport .ant-form-item-explain {
  display: none;
}
.rowDownloadReport .ant-form-item {
  margin-bottom: 10px;
}
.titleModalAgreement {
  color: #003399;
  font-weight: bolder;
  font-size: 17px;
}
.statusQuestion {
  font-weight: bold;
  margin-right: 10px;
}
.statusModal {
  display: flex;
  flex-direction: row;
}
.answerInline {
  margin-bottom: 5;
}
.inputAnswer {
  margin-right: 15px;
}
.btnRadioVideo {
  width: 100%;
}
.btnRadioAudio {
  width: 100%;
}
.btnRadioPdf {
  width: 100%;
}
.btnRadioEmbed {
  width: 100%;
}
.btnRadioIcon {
  color: #d82d2d;
  margin: 0 10px 0 0;
}
.RadioFileType {
  width: 100%;
  margin: 10px 0;
}
.btnDeleteContentSlide {
  background-color: #d82d2d;
  border: none;
  margin: 10px 0;
}
.containerFileInformation {
  padding: 2vh 5vh;
}
.embedLinkDesc {
  margin-top: 5px;
  color: #444444;
  font-size: 12px;
}

.fileNameInformation {
  word-wrap: break-word;
}
.uploadFormat {
  font-size: 12px;
  margin-top: -40px;
}
.uploadFormatRequired {
  font-size: 12px;
}
.formAnswer {
  width: 93%;
  margin: 4px 4px;
}
.cardTitleQuestionForm .ant-card-head {
  color: #ecf0ef;
  background-color: #444444;
  font-size: 18px;
  font-weight: bolder;
}
.btnAddQuestionForm {
  background-color: #003399;
  color: #ecf0ef;
  border-radius: 5px;
}
.btnDeleteQuestionForm {
  background-color: #d82d2d;
  color: #ecf0ef;
  float: right;
}
.inputQuestionForm {
  border: 1px solid #dadada;
  border-radius: 5px;
}
.inputChannel {
  width: 60% !important;
  border: 1px solid #dadada;
  border-radius: 5px;
}
.formAnswerQuestion {
  width: 93%;
  margin: 4px 4px;
  border: 1px solid #dadada;
  border-radius: 5px;
}
.labelRequiredQuestion {
  margin-top: -20px;
}
.tabPaneFeedbackBank {
  padding: 15px;
  background: #003399;
  color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.tabFeedbackBank .ant-tabs-tab {
  padding: 0;
  margin-right: 0px;
}
.tabFeedbackBank .ant-tabs-bar {
  margin-bottom: 0px;
  border-bottom: 0px;
}
.tabFeedbackBank .ant-tabs-ink-bar {
  background-color: #ffffff;
}
.tabFeedbackBank .ant-tabs-tab-active .tabPaneFeedbackBank {
  background: #ffffff;
  color: #003399;
}
.breadcumbModal {
  display: flex;
  flex-direction: row;
}
.descCatatan {
  margin-right: 5px;
}
.lineModalFeedbackBank {
  border: 1px solid #ecf0ef;
  border-right: none;
  border-left: none;
  border-bottom: none;
  margin: 16px 0;
}
.containerMultipleChoiceQuestion {
  background-color: #fafbfb;
  height: 250px;
  max-height: 250px;
  border-radius: 10px 10px 0 0;
  padding: 16px;
  overflow: auto;
}
.containerItemListMultipleChoiceQuestion {
  overflow: auto;
  background-color: #ecf0ef;
  padding: 8px 16px;
  border-radius: 5px;
  margin-bottom: 8px;
}
.inputFeedbackCategory {
  border: 1px solid #dadada;
  border-radius: 5px;
}
.containerTotalMultipleChoiceQuestion {
  background-color: #ecf0ef;
  padding: 8px;
  border-radius: 0 0 10px 10px;
}
.containerAnswerFeedbackBank {
  padding: 0 24px;
}
.containerAnswerItem {
  margin: 0 0 -13px 0;
}
.inputChannelFeedbackBank {
  width: 60% !important;
  border: 1px solid #dadada;
  border-radius: 5px;
}
.inputModuleFeedbackBank {
  width: 80% !important;
  border: 1px solid #dadada;
  border-radius: 5px;
}
.spanAnswerFeedbackBank {
  margin-top: 5px;
}
.spanPointFeedbackBank {
  padding: 5px 15px 0;
  text-align: center;
}
.cardMultipleChoiceFeedbackBank .ant-card-head {
  color: #ecf0ef;
  background-color: #444444;
  font-size: 18px;
  font-weight: bolder;
}
.cardFreeTextFeedbackBank .ant-card-head {
  color: #ecf0ef;
  background-color: #444444;
  font-size: 18px;
  font-weight: bolder;
}
.switchStatusFeedbackBank {
  border: 1px solid #dadada;
}
.inputAnswerFeedbackBank {
  border: 1px solid #dadada;
  border-radius: 5px;
}
.inputPointFeedbackBank {
  border: 1px solid #dadada;
  border-radius: 5px;
}
.btnCari {
  border-radius: 10px;
  margin-top: 30px;
}
.btnResetReport {
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 30px;
  border-color: #003399;
  background: white;
  color: #003399;
}
.txtConfirmStatus {
  text-align: center;
  margin: 20px 0px 20px 0px;
}
.questionTypeHeading {
  background-color: #444444;
  color: white;
  padding: 5px 10px;
  margin-bottom: 25px;
}
.lineModalDetailFeedback {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dadada;
  margin-bottom: 40px;
  padding-top: 20px;
}
.textHighlightYellow {
  background-color: #fffdcc;
}
.textHighlightGrey {
  background-color: #ecf0ef;
}
.containerItemFeedback {
  align-items: center;
}
.containerQuestion {
  padding: 5px 10px;
  border-radius: 7px;
}
.containerMultipleChoiceAnswer {
  padding: 5px 10px;
  border-radius: 7px;
}
.containerMultipleChoicePoint {
  padding: 5px 10px;
  border-radius: 7px;
}
.containerEssayAnswer {
  padding: 5px 10px;
  border-radius: 7px;
}
.btnClose {
  background: #444444;
  color: white;
  border-radius: 10px;
  border-color: #444444;
  margin-left: 50%;
}
.totalPoint {
  font-size: 16px;
  float: right;
  margin-bottom: 3vh;
}
.cardDetailFeedback {
  border-radius: 10px;
}
.certificateModule {
  margin: 30px 0px 5px 0px;
}
.modalDetailQAProcess {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
}
.paragraphTabItemPending {
  margin-top: 20px;
}
.spanTabItemPending {
  font-weight: bold;
  color: #003399;
}
.labelBtnFilter {
  font-weight: bold;
  margin-left: 10px;
}
.labelInputFormModal {
  font-weight: bold;
  margin-bottom: 10px;
}
.siderCollapseFalse {
  margin-left: 200px;
}
.siderCollapseTrue {
  margin-left: 80px;
}
.divCheckbox {
  display: flex;
  flex-direction: column;
}
.divParticipantLOSType {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.borderSolid {
  border-top: 1px solid #bfbfbf;
  margin-top: 20px;
  margin-bottom: 20px;
}
.divClassHandoutFile {
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  margin-top: 50px;
}
.stepsNonClassELearning {
  display: none;
}
.btnFilter {
  margin-top: 30px;
}
.descCheckbox {
  margin-left: 10px;
}
.labelSwitchStatusModule {
  font-weight: bold;
  margin-top: 10px;
}
.spanLearningHour {
  margin-left: 10px;
}
.defaultLearningHourRequired {
  color: red;
  margin-bottom: 5px;
  font-size: 15px;
  padding-bottom: 3px;
}
.titleModuleMigration {
  font-family: frutiger, sans-serif;
  font-size: 22px;
}
.disclaimerParticipant {
  display: flex;
  flex-direction: row;
  background-color: #fffdcc;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 10px;
}
.iconWarningModule {
  font-size: 20px;
  color: #f69c00;
  margin-top: 11px;
}
.spanDisclaimerModule {
  font-weight: bold;
  padding-left: 2px;
}
.labelModule {
  font-weight: bold;
  color: #003399;
  font-size: 17px;
}
.cpdModule {
  margin-bottom: 30px;
}
.btnAssignParticipant {
  background-color: #ffffff;
  color: #009ee0;
  border: 2px solid #009ee0;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  width: 150px;
  height: 40px;
  margin: 20px 15px 0px 0px;
}
.btnLanjutkan {
  margin-left: 3px;
}
.labelCategory {
  font-weight: bold;
  margin-left: 5px;
}
.answerContentFeedback {
  margin-bottom: 32px;
}
.btnFeedbackBank {
  margin-top: 8px;
}
.selectInTable {
  min-width: 500px;
  max-width: 500px;
}
.btnCloseReport {
  margin-top: 40px;
  width: 120px;
  height: 40px;
  border-radius: 13px;
  background-color: #444444;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.infoDownloadingReport {
  font-weight: bold;
  font-size: 18px;
  /* margin-top: 15px; */
}
.iconDownload {
  font-size: 2em;
  color: white;
  text-align: center;
  padding: 9px;
  background-color: #009ee0;
  border-radius: 60px;
  width: 60px;
  height: 60px;
  justify-content: center;
}

.contentModalDownload {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background-color: white;
}
.contentModalValidation {
  text-align: center;
  font-size: 15px;
  padding-top: 10px;
}
.msgErrorValidation {
  color: #ff4d4f;
}
.labeldisclaimer {
  background-color: #fff9df;
  padding: 5px;
  margin-bottom: 15px;
}
.imgHeaderRecruitment {
  width: 100%;
}
.logoZurichRecruitment {
  width: 35%;
  margin: 20px 0 10px 10px;
  padding: 10px;
}
.termsTitleRecruitment {
  background-color: #3e3e3e;
  color: white;
  padding: 10px;
  border-radius: 3px;
}
.termsContentRecruitment {
  padding: 10px;
}
.contentRecruitment {
  height: 100%;
  padding: 0px 10px 70px 10px;
}
.footerRecruitment {
  background-color: #3e3e3e;
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow-y: hidden;
  font-size: 10px;
}
.btnTerms {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btnTermsNext {
  border-radius: 8px;
  width: 35%;
  padding: 3px;
  background-color: #003399;
}
hr.hrTerms {
  border-top: 1px solid #bfbfbf;
  margin-top: 20px;
}
.descTerms {
  margin-top: 10px;
  height: 55vh;
  white-space: pre-line
}
.bgSuccessRecruitment {
  background-image: url('./Assets/Images/bg-success-recruitment-web.png');
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logoZurichSuccess {
  width: 130px;
  height: 80px;
  position: absolute;
  top: 100px;
}
.containerSuccess {
  background-color: #003399;
  border-radius: 20px;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 60px;
  padding-right: 60px;
  position: absolute;
  top: 230px;
  margin: 0px 20px 0px 20px;
}
.iconCheck {
  color: #ffffff;
  font-size: 45px;
  margin-bottom: 24px;
}
.textBox {
  font-family: frutigerBold, sans-serif;
  font-size: 20px;
  color: #ffffff;
}
.textBox2 {
  font-family: frutigerLight, sans-serif;
  color: #ffffff;
}
.lineBox {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #bfbfbf;
  width: 80%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.txtCopyRight {
  color: #3e3e3e;
  position: absolute;
  bottom: 30px;
}
.recruitmentFormContainer {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
}
.recruiterFormEnthusiasm {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-top: 10px;
}
.recruiterLabelImgFormEnthusiasm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
}
/* .recruiterFormEnthusiasm > div { */
.recruiterImgFormEnthusiasm {
  width: 50px;
  padding: 10px;
  border: 1px solid #dadada;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divBtnRecruitmentFormModal {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 5px;
}
.btnCancelRecruitmentFormModal {
  border: none;
  width: 100%;
  color: red;
}
.btnCancelDisabled {
  margin: auto;
  display: block;
  background: rgba(0, 0, 102, 0.4);
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
}
.contentRecruitmentFormModal {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
  background: #ffffff;
  max-height: 70vh;
  overflow-x: auto;
}
.divFlagPhoneNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
  height: 31.6px;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-weight: 700;
}
.divRecruitmentFormPhoneNumber {
  display: flex;
  gap: 5px;
  padding-top: 10px;
}
.recruitmentFormPhoneNumber {
  width: 100%;
  margin-bottom: 0px;
}
.inputFormRecruitment {
  margin-bottom: 0px;
}
.addMarginBtm {
  margin-bottom: 1em;
}
.pNotFound {
  font-size: 18px;
  color: #9e9995;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px
}
.divNotFoundImage{
  display: flex;
  justify-content: center;
}
.btnBack404{
  display: flex;
  justify-content: center;
}
/* untuk mobile */
@media only screen and (min-width: 280px) and (max-width: 376px) {
  .descTerms {
    margin-top: 10px;
    height: 42vh;
  }
  .logoZurichSuccess {
    width: 100px;
    height: 60px;
    top: 90px;
  }
  .iconCheck {
    font-size: 30px;
  }
  .containerSuccess {
    margin: 0px 30px 0px 30px;
    top: 200px;
  }
  .textBox {
    font-size: 14px;
  }
  .textBox2 {
    font-size: 13px;
  }
  .recruiterLabelImgFormEnthusiasm,
  .recruiterImgFormEnthusiasm {
    width: 50px;
  }
}
/* untuk tablet */
@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .recruitmentpage {
    margin: auto;
    padding-left: 80px;
    padding-right: 80px;
  }
  .footerRecruitment {
    width: calc(100% - 160px);
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btnTermsNext {
    border-radius: 8px;
    width: 15%;
  }
  .logoZurichRecruitment {
    width: 15%;
    margin: 20px 0 20px 0;
  }
  .contentRecruitment {
    height: 100%;
    padding: 0px 10px 60px 10px;
  }
  .descTerms {
    margin-top: 10px;
    height: 600px;
  }
  .logoZurichSuccess {
    top: 130px;
  }
  .containerSuccess {
    top: 250px;
  }
  .textBox {
    font-size: 23px;
  }
  .textBox2 {
    font-size: 16px;
  }
  .divBtnRecruitmentFormModal {
    width: 70%;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .recruiterLabelImgFormEnthusiasm,
  .recruiterImgFormEnthusiasm {
    width: 85px;
  }
  .h1NotFound {
    margin-top: 10vh;
    font-size: 45px;
  }
}
/* untuk desktop web */
@media only screen and (min-width: 1024px) {
  .recruitmentpage {
    margin: auto;
    padding-left: 180px;
    padding-right: 180px;
  }
  .footerRecruitment {
    width: calc(100% - 360px);
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .recruitmentFormContainer {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    padding: 25px 0px 0px 0px;
  }
  .recruitmentFormContainer > div {
    width: 50%;
  }
  .btnTermsNext {
    border-radius: 8px;
    width: 15%;
  }
  .logoZurichRecruitment {
    width: 15%;
    margin: 20px 0 20px 0;
  }
  .contentRecruitment {
    height: 100%;
    padding: 0px 10px 70px 10px;
  }
  .descTerms {
    margin-top: 10px;
    height: 190px;
  }
  .containerSuccess {
    margin: 0;
    top: 220px;
  }
  .divBtnRecruitmentFormModal {
    width: 70%;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .recruiterLabelImgFormEnthusiasm,
  .recruiterImgFormEnthusiasm {
    width: 85px;
  }
  .pNotFound {
    font-size: 24px;
    text-align: left;
    margin-left: 5vw;
    width: 50vw;
  }
  .divNotFoundImage{
    display: flex;
    justify-content: left;
  }
  .h1NotFound {
    font-size: 45px;
    margin-top: 5vh;
    text-align: left;
  }
  .btnBack404{
    justify-content: left;
  }
}
