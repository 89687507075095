.day {
  position: relative;
  width: calc(100% / 7);
  min-height: 120px;
  display: inline-table;
  background-color: #ffffff;
  padding: 5px;
  margin: 0;
  box-sizing: border-box;
  z-index: 1;
  border: 1px #dadada solid;
}
.day:hover {
  position: relative;
  width: calc(100% / 7);
  height: 120px;
  display: inline-table;
  background-color: #d6f3ff;
  padding: 5px;
  margin: 0;
  box-sizing: border-box;
  z-index: 1;
  border: 1px #dadada solid;
}
.notMonth {
  position: relative;
  width: calc(100% / 7);
  height: 120px;
  display: inline-table;
  background-color: white;
  padding: 5px;
  margin: 0;
  box-sizing: border-box;
  z-index: 1;
  border: 1px #dadada solid;
  opacity: 0.5;
}
.today {
  position: relative;
  width: calc(100% / 7);
  height: 120px;
  display: inline-table;
  background-color: #d6f3ff;
  padding: 5px;
  margin: 0;
  box-sizing: border-box;
  z-index: 1;
  border: 1px #dadada solid;
}
.containerDay {
  width: 100%;
}
.dayName {
  position: relative;
  width: calc(100% / 7);
  height: 50px;
  display: inline-table;
  color: #3e3e3e;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  background-color: #fafbfb;
  border: 1px #dadada solid;
  padding-top: 10px;
}
.ant-select.selectExamSchedule.ant-select-single.ant-select-show-arrow
  div.ant-select-selector {
  border: none;
  border-radius: 10px;
  background-color: #ECF0EF;
  color: #444444;
  font-weight: bold;
  font-size: 25px;
  height: 50px;
  padding-top: 10px;
}
